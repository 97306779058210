const serviceController = {
  tabs: document.querySelectorAll('.services__tab-item'),
  tabContent: document.querySelectorAll('.services__cards'),
  cards: document.querySelectorAll('a[data-modal-service="true"]'),
  init() {
    this.switchTab()
    this.openModal()
  },
  switchTab() {

    this.tabs.forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault()

        const tabName = element.getAttribute("id")

        this.tabContent.forEach(eachContent => {

          if (eachContent.matches(`[aria-labelledby="${tabName}"]`)) {
            eachContent.classList.add('services__cards--active')
            eachContent.setAttribute('hidden', false)
            eachContent.setAttribute('tabindex', '0')

          } else {
            eachContent.classList.remove('services__cards--active')
            eachContent.setAttribute('hidden', true)
            eachContent.setAttribute('tabindex', '-1')
          }

        })

        this.tabs.forEach(eachElement => {
          eachElement.classList.remove('services__tab-item--active')
          eachElement.setAttribute('aria-selected', false)
          eachElement.setAttribute('tabindex', '-1')
        })

        element.classList.add('services__tab-item--active')
        element.setAttribute('aria-selected', true)
        element.setAttribute('tabindex', '0')
      })
    })
  },
  openModal() {
    this.cards.forEach(card => {
      card.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()
        const text = card.querySelector('.services__card-description').textContent
        document.querySelector('.service-modal__title').textContent = text
        MicroModal.show('modal-service')
      })
    })
  }
}

serviceController.init()